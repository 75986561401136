import { Button, Card, Detail, Grid, Checkbox, H3, H6, Select, UList, UListItem } from "@maggioli-design-system/react";
import { ChangeEvent, useState } from "react";
import * as Constants from "../lib/Constants";

const __continue = (period: string, areas: string[], afunc: Function, bfunc: Function) => {
    const data: Object = {
        "period": period,
        "areas": areas,
        // 'type' : areas.length > 0 ? 'pro' : areas === 'all' ? 'premium' : 'one'
    };
    sessionStorage.setItem("sub", JSON.stringify(data));
    afunc(false);
    bfunc(true);
};

interface ISubscriptionPlan {
    setShow: Function;
    setShow2: Function;
}

// Card iscrizione area singola
const SubscriptionPlanOne = ({ setShow, setShow2 }: ISubscriptionPlan): JSX.Element => {
    const [period, setPeriod] = useState<string>("1 anno");
    const [area, setArea] = useState<string>("---");

    return (
        <Card gutter="none" className="overflow-hidden p-0" template="subscription-plan">
            <H3 className="background-color-adjust-tone-10 color-adjust-tone-20 p-4 text-center">One</H3>
            <Detail className="background-color-adjust-tone-19 p-4 hidden xxl:block">
                Abbonati ad una delle aree tematiche di biblioteca digitale:
            </Detail>
            <Grid gutter="small" className="background-color-adjust-tone p-4" rows="fit-vertically">
                <Select
                    label="Area tematica"
                    data={["---", ...Constants.subscriptionAreas]}
                    value={area}
                    onChange={(e: ChangeEvent<HTMLFormElement>) => setArea(e.target.value)}
                />
                <Select
                    label="Durata abbonamento"
                    data={Constants.subscriptionPeriods}
                    value={period}
                    onChange={(e: ChangeEvent<HTMLFormElement>) => setPeriod(e.target.value)}
                />
            </Grid>
            <div className="background-color-adjust-tone-19 p-4">
                <Button disabled={area === "---"} width="fill" variant="secondary" onClick={() => __continue(period, [area], setShow, setShow2)}>
                    Scegli piano
                </Button>
            </div>
        </Card>
    );
};

// Card iscrizione più aree
const SubscriptionPlanPro = ({ setShow, setShow2 }: ISubscriptionPlan): JSX.Element => {
    const [period, setPeriod] = useState<string>("1 anno");
    const [areas, setAreas] = useState<string[]>([]);

    const selectArea = (area: string) => {
        if (!areas.includes(area)) {
            setAreas((prev) => [...prev, area]);
        } else {
            setAreas(areas.filter((elem) => elem !== area));
        }
    };

    return (
        <Card gutter="none" className="overflow-hidden p-0" template="subscription-plan">
            <H3 className="background-color-status-info-10 color-status-info-20 p-4 text-center">Plus</H3>
            <Detail className="background-color-status-info-19 p-4 hidden xxl:block">Abbonati a più aree tematiche di biblioteca digitale:</Detail>
            <Grid gutter="small" className="background-color-adjust-tone p-4" rows="fit-vertically">
                <Grid className="gap-2">
                    <H6>Scegli aree tematiche</H6>
                    {Constants.subscriptionAreas.map((elem, index) => {
                        return (
                            <Checkbox key={index} isChecked={areas.includes(elem)} onChange={() => selectArea(elem)}>
                                {elem}
                            </Checkbox>
                        );
                    })}
                </Grid>
                <Select
                    label="Durata abbonamento"
                    data={Constants.subscriptionPeriods}
                    value={period}
                    onChange={(e: ChangeEvent<HTMLFormElement>) => setPeriod(e.target.value)}
                />
            </Grid>
            <div className="background-color-status-info-19 p-4">
                <Button width="fill" variant="info" disabled={areas.length === 0} onClick={() => __continue(period, areas, setShow, setShow2)}>
                    Scegli piano
                </Button>
            </div>
        </Card>
    );
};

// Card iscrizione a tutte le aree
const SubscriptionPlanPremium = ({ setShow, setShow2 }: ISubscriptionPlan): JSX.Element => {
    const [period, setPeriod] = useState<string>("1 anno");

    return (
        <Card gutter="none" className="overflow-hidden p-0" template="subscription-plan">
            <H3 className="background-color-status-success-10 color-status-success-20 p-4 text-center">Premium</H3>
            <Detail className="background-color-status-success-19 p-4 hidden xxl:block">
                Abbonati a tutte le aree tematiche di biblioteca digitale:
            </Detail>
            <Grid gutter="small" className="background-color-adjust-tone p-4" rows="fit-vertically">
                <Grid gutter="xsmall">
                    <H6>Scegli aree tematiche</H6>
                    <UList
                        icon="crud-save"
                        iconClassName="color-status-success-10"
                        iconSize="normal"
                        className="text-secondary text-secondary--caption gap-2 pl-0"
                        autoPunctuation={false}
                    >
                        {Constants.subscriptionAreas.map((elem, index) => (
                            <UListItem key={index}>{elem}</UListItem>
                        ))}
                    </UList>
                </Grid>
                <Select
                    label="Durata abbonamento"
                    data={Constants.subscriptionPeriods}
                    value={period}
                    onChange={(e: ChangeEvent<HTMLFormElement>) => setPeriod(e.target.value)}
                />
            </Grid>
            <div className="background-color-status-success-19 p-4">
                <Button width="fill" variant="success" onClick={() => __continue(period, ["all"], setShow, setShow2)}>
                    Scegli piano
                </Button>
            </div>
        </Card>
    );
};

interface IResultSelectedPlan {
    setShow: Function;
    backToFirst: Function;
    data: {
        areas: string[];
        period: string;
    };
}

const ResultSelectedPlan = ({ setShow, backToFirst, data }: IResultSelectedPlan): JSX.Element => {
    const __backTo = () => {
        setShow(false);
        backToFirst(true);
    };

    const subType = () => {
        if (data !== null) {
            return data.areas.length > 1 ? "Pro" : data.areas[0] === "all" ? "Premium" : "One";
        } else {
            return "";
        }
    };

    const bgColor = () => {
        switch (subType()) {
            case "Pro":
                return "status-info";
            case "Premium":
                return "status-success";
            default:
                return "adjust-tone";
        }
    };

    const btnVariant = () => {
        switch (subType()) {
            case "Pro":
                return "info";
            case "Premium":
                return "success";
            default:
                return "secondary";
        }
    };

    const areas = () => {
        if (data !== null) {
            return subType() === "Premium"
                ? Constants.subscriptionAreas.map((elem, index) => <UListItem key={index}>{elem}</UListItem>)
                : data.areas.map((elem, index) => <UListItem key={index}>{elem}</UListItem>);
        } else {
            return "";
        }
    };

    // Riepilogo abbonamento selezionato
    return (
        <Card gutter="none" className="overflow-hidden p-0" template="subscription-plan">
            {/* Titolo tipo abbonamento */}
            <H3 className={`background-color-${bgColor()}-10 color-${bgColor()}-20 p-4 text-center`}>{subType()}</H3>

            <Grid gutter="small" className="background-color-adjust-tone p-4" rows="sfit-vertically">
                {/* Aree scelte per l'abbonamento */}
                <Grid gutter="xsmall">
                    <H6>Aree tematiche selezionate</H6>
                    {data !== null && (
                        <UList
                            icon="crud-save"
                            iconClassName={`color-${bgColor()}-10`}
                            iconSize="normal"
                            className="text-secondary text-secondary--caption gap-2 pl-0"
                            autoPunctuation={false}
                        >
                            {areas()}
                        </UList>
                    )}
                </Grid>

                {/* Durata abbonamento */}
                <Grid gutter="xsmall">
                    <H6>Durata abbonamento</H6>
                    {data !== null && (
                        <UList
                            icon="input-calendar"
                            iconClassName={`color-${bgColor()}-10`}
                            iconSize="normal"
                            className="text-secondary text-secondary--caption gap-2 pl-0"
                            autoPunctuation={false}
                        >
                            <UListItem>{data.period}</UListItem>
                        </UList>
                    )}
                </Grid>
            </Grid>

            {/* Bottone rivedi abbonamento */}
            <div className={`background-color-${bgColor()}-19 p-4`}>
                <Button width="fill" variant={btnVariant()} onClick={() => __backTo()} icon="action-back">
                    Rivedi la selezione
                </Button>
            </div>
        </Card>
    );
};

export { SubscriptionPlanOne, SubscriptionPlanPro, SubscriptionPlanPremium, ResultSelectedPlan };
