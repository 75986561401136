import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "typeface-karla";
import "typeface-roboto";

import App from "./App";
// import reportWebVitals from "./reportWebVitals";
import * as SubService from "./services/Subscriptions";
import { TailSpin } from "react-loader-spinner";
import Singleton from "./lib/Singleton";
import { expressURL, getCookie, keycloakPayload, keycloakRedirectUrl, setCookie } from "./lib/Constants";
import { Keycloak } from "keycloak-auth";
import { deleteAllCookies } from "./lib/Functions";

Keycloak.init(keycloakPayload, keycloakRedirectUrl);

const saveActiveToken = async () => {
    const res = await fetch(`${expressURL}/api-pa-authenticate-guest`, {
        method: "POST",
        credentials: "include",
    });
    if (!res.ok) {
        return false;
    }
    const data = await res.json();
    return data.token;
};

const getApiPaToken = async (token) => {
    const res = await fetch(`${expressURL}/api-pa-authenticate`, {
        method: "POST",
        headers: {
            "Authorization": "Bearer " + token,
        },
    });
    if (!res.ok) {
        return false;
    }
    const data = await res.json();
    return data;
};

const Render = () => {
    const [ip, setIp] = useState("");
    const [idProdotti, setIdProdotti] = useState([]);
    const [render, setRender] = useState(false);

    useEffect(() => {
        loader();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getIP = async () => {
        const res = await fetch(`${expressURL}/api/users/get-ip`);
        if (!res.ok) {
            return;
        }
        const data = await res.json();
        setIp(data.ip);
    };

    const __getIdProdotti = async (token) => {
        const res = await SubService.getIdProdotti(token);
        if (res.status !== 200) {
            return;
        }
        setIdProdotti(res.data);
    };

    const checkLogin = async () => {
        let accessToken = getCookie("access_token");
        const refreshToken = getCookie("refresh_token");

        if (refreshToken === null) {
            return authentication();
        }
        if (accessToken === null) {
            accessToken = await loadTokens(refreshToken, true);
        }
        return accessToken;
    };

    const loadTokens = async (code, refresh = false) => {
        const dataKey = await Keycloak.auth().getToken(code, refresh);
        if (!dataKey.access_token) {
            return null;
        }

        setCookie("access_token", dataKey.access_token, dataKey.expires_in);
        setCookie("refresh_token", dataKey.refresh_token, dataKey.refresh_expires_in);

        return dataKey.access_token;
    };

    const authentication = async () => {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get("code");
        if (code === null) {
            deleteAllCookies();
            return null;
        }
        const accessToken = await loadTokens(code);
        if (accessToken === null) {
            return null;
        }
        return accessToken;
    };

    const loader = async () => {
        await import("@maggioli-design-system/icons/dist/mgg-icons.css");
        await import("@maggioli-design-system/styles/dist/css/styles.min.css");
        await import("@maggioli-design-system/design-tokens/dist/css/vars-rgb-channels.css");
        await import("./assets/css/main.css");
        await import("./assets/css/App.css");
        const guestTokenAPIPA = await saveActiveToken();

        let tempToken = await checkLogin();
        let activeToken = false;
        if (tempToken !== null) {
            const apiPaToken = await getApiPaToken(tempToken);
            if (apiPaToken) {
                setCookie("api-pa-token", apiPaToken.token, apiPaToken.expire_in);
                activeToken = apiPaToken.token;
            }
        }
        await getIP();
        await __getIdProdotti(guestTokenAPIPA);
        Singleton.getActiveToken(!activeToken ? guestTokenAPIPA : activeToken);
        setRender(true);
    };

    const loaderStyle = {
        position: "fixed",
        left: "50%",
        top: "45%",
    };

    return render ? (
        <App ip={ip} idProdotti={idProdotti} />
    ) : (
        <div className="loader">
            <TailSpin style={loaderStyle} color="#0041B9" />
        </div>
    );
};

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <Render />
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log());
