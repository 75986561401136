import { ChangeEvent, useState } from "react";
import { Header, HeaderLogo, HeaderMenu, InputText, Button } from "@maggioli-design-system/react";
import { Route, Link, useNavigate } from "react-router-dom";
import { deleteAllCookies, scrollTop } from "../../lib/Functions";
import SubscribeStep1 from "../../views/SubscribeStep1";
import SubscribeStep2 from "../../views/SubscribeStep2";
import TopMenu from "../TopMenu";
/* import Message from '../Message' */
import { IProdotto } from "../../Interfaces/IProdotto";
import { ILoader } from "../../Interfaces/ILoader";
import { Keycloak } from "keycloak-auth";
import { getCookie } from "../../lib/Constants";

interface IHandlerText {
    searchText: string;
    showSearch: boolean;
    setSearchText: Function;
}

interface IHandlerMatch {
    match: {
        url: string;
    };
    setMatch: Function;
}
interface IHeaderBiblioteca {
    showModal: boolean;
    showModal2: boolean;
    setShowModal: Function;
    setShowModal2: Function;
    ip: string;
    idProdotti: IProdotto[];
    handlerText: IHandlerText;
    handlerMatch: IHandlerMatch;
    loader: ILoader;
}

const HeaderBiblioteca = ({
    showModal,
    showModal2,
    setShowModal,
    setShowModal2,
    ip,
    idProdotti,
    handlerText,
    handlerMatch,
    loader,
}: IHeaderBiblioteca): JSX.Element => {
    const [visible, setVisible] = useState<boolean>(false);

    const isLogged: boolean = getCookie("refresh_token") !== null;

    /* const [message, setMessage] = useState<string>("")
	const [messageVisible, setMessageVisible] = useState<boolean>(false) */

    const history = useNavigate();

    const handler = {
        match: handlerMatch.match,
        setMatch: handlerMatch.setMatch,
        handlerText: handlerText,
    };

    const logout = async () => {
        const refreshToken: string | null = getCookie("refresh_token");
        if (refreshToken !== null) {
            await Keycloak.auth().logout(refreshToken);
        }
        deleteAllCookies();
        window.location.href = "/";
    };

    const goToHome = () => {
        scrollTop();
        handlerMatch.setMatch({ url: "/", params: {} });
        handlerText.setSearchText("");
    };

    const goToFavorites = () => {
        history("/favorites");
        handlerMatch.setMatch({ url: "/favorites", params: {} });
    };

    const randomKey = (): number => {
        return Math.random() * 1000;
    };

    return (
        <div>
            <Header className="z-20" lastChild="to-right">
                {/* Logo header */}
                <Link className="lg:pl-8" to={"/"} onClick={() => goToHome()}>
                    <HeaderLogo src={`${process.env.PUBLIC_URL}/assets/images/logo-biblioteca-digitale.png`} />
                </Link>

                <HeaderMenu className="overflow-visible lg:pr-8" onClick={() => setVisible(!visible)}>
                    {/* Barra di ricerca */}
                    {handlerText.showSearch && (
                        <InputText
                            key={handlerMatch.match.url}
                            className="overflow-visible"
                            icon="data-search"
                            placeholder="Cerca nella Biblioteca..."
                            value={handlerText.searchText}
                            onChange={(e: ChangeEvent<HTMLFormElement>) => handlerText.setSearchText(e.target.value)}
                        />
                    )}

                    {/* Bottone preferiti */}
                    {isLogged ? (
                        <Button onClick={() => goToFavorites()} icon="data-favorite" horizontalPadding="none">
                            Preferiti
                        </Button>
                    ) : (
                        <Button variant="secondary-outline" onClick={() => window.location.replace(Keycloak.auth().createLoginString())}>
                            Accedi
                        </Button>
                    )}

                    {/* Bottone visualizza account */}
                    {isLogged && (
                        <Button onClick={() => history("/account")} icon="user" horizontalPadding="none">
                            Account{" "}
                        </Button>
                    )}

                    {/* Bottone logout */}
                    {isLogged ? (
                        <Button icon="action-logout" variant="secondary-outline" onClick={() => logout()} horizontalPadding="none">
                            Esci
                        </Button>
                    ) : (
                        <Button variant="success" onClick={() => setShowModal(true)}>
                            Come abbonarsi
                        </Button>
                    )}
                </HeaderMenu>
            </Header>

            {/* Modale sub primo step */}
            <SubscribeStep1 show={showModal} setShow={setShowModal} setShow2={setShowModal2} />

            {/* Modale sub secondo step */}
            <SubscribeStep2 key={randomKey()} show={showModal2} setShow={setShowModal2} backToFirst={setShowModal} ip={ip} loader={loader} />

            {/* Barra delle categorie */}
            <TopMenu
                loader={loader}
                handler={handler}
                idProdotti={idProdotti}
                ip={ip}
                setShowModal={setShowModal}
                visible={visible}
                setVisible={setVisible}
            />
            {/* <Message
				visible={messageVisible}
				setVisible={setMessageVisible}
				message={message} /> */}
        </div>
    );
};

export default HeaderBiblioteca;
