import { useState, useEffect } from "react";
import { clientID, clientSecret, expressURL, setCookie } from "./Constants";

interface IResult {
    status: number;
}

// Hook
const useWindowSize = (): number => {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [windowSize, setWindowSize] = useState<number>(undefined);

    useEffect(() => {
        // Handler to call on window resize
        function handleResize() {
            // Set window width/height to state
            setWindowSize(window.innerWidth);
        }

        // Add event listener
        window.addEventListener("resize", handleResize);

        // Call handler right away so state gets updated with initial window size
        handleResize();

        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount

    return windowSize;
};

// mappa il nome abbreviato dell'area con il rispettivo intero
const replaceMenuName = (data: string): string => {
    data = data.toLowerCase();
    if (data === "LM Bilancio".toLowerCase()) return "Bilancio, Contabilità e Tributi";
    if (data === "LM Personale".toLowerCase()) return "Personale e organizzazione";
    if (data === "LM Amministrazione".toLowerCase()) return "Amministrazione e PA Digitale";
    if (data === "LM Polizia".toLowerCase()) return "Polizia e Attività economiche";
    if (data === "LM Servizi Sociali".toLowerCase()) return "Servizi Sociali e Scuola";
    if (data === "LM Ufficio Tecnico".toLowerCase()) return "Ufficio Tecnico";
    if (data === "LM Demografici".toLowerCase()) return "Servizi Demografici";
    if (data === "LM Appalti".toLowerCase()) return "Appalti e Contratti";
};

// mappa il nome abbreviato dell'area con il rispettivo intero
const replaceAreaName = (data: string): string => {
    data = data.toLowerCase();
    if (data === "LM Bilancio".toLowerCase()) return "Bilancio e Contabilità,Tributi";
    if (data === "LM Personale".toLowerCase()) return "Personale e organizzazione";
    if (data === "LM Amministrazione".toLowerCase()) return "Amministrazione e PA Digitale";
    if (data === "LM Polizia".toLowerCase()) return "Polizia,Attività economiche";
    if (data === "LM Servizi Sociali".toLowerCase()) return "Servizi Sociali e Scuola";
    if (data === "LM Ufficio Tecnico".toLowerCase()) return "Ufficio Tecnico";
    if (data === "LM Demografici".toLowerCase()) return "Servizi Demografici";
    if (data === "LM Appalti".toLowerCase()) return "Appalti e Contratti";
};

// scrolla in cima alla pagina
const scrollTop = () => {
    window.scrollTo({
        top: 0,
        behavior: "smooth",
    });
};

// controlla la risposta del server
const serverResponseOK = (result: IResult): boolean => {
    return result.status === 200;
};

export const checkIP = async (ip: string, token: string, idProdotti: string[]) => {
    const formData = new URLSearchParams();
    const headerList = new Headers();
    headerList.append("Content-Type", "application/x-www-form-urlencoded");
    headerList.append("Accept", "*/*");
    headerList.append("Authorization", "Bearer " + token);
    const response = await fetch(`${expressURL}/api/users/ip-check`, {
        method: "POST",
        headers: headerList,
        body: formData,
    });

    if (!response.ok) {
        return null;
    }
    const data = await response.json();
    return data;
};

export const enableIP = async (ip: string, token: string, email: string, subID: string, idProdotti: string[]) => {
    const formData = new URLSearchParams();
    const headerList = new Headers();
    headerList.append("Content-Type", "application/x-www-form-urlencoded");
    headerList.append("Accept", "*/*");
    headerList.append("Authorization", "Bearer " + token);
    formData.append("email", email);
    formData.append("sub", subID);
    const response = await fetch(`${expressURL}/api/users/ip-enable`, {
        method: "POST",
        headers: headerList,
        body: formData,
    });
    return response.ok;
};

export const introspect = async (access_token: string) => {
    const formSub = new URLSearchParams();
    const headersList = {
        "Accept": "*/*",
        "Authorization": "Bearer " + access_token,
        "Content-Type": "application/x-www-form-urlencoded",
    };
    formSub.append("client_id", clientID);
    formSub.append("client_secret", clientSecret);

    const response = await fetch("https://sso.maggioli.com/realms/maggioli/protocol/openid-connect/userinfo", {
        method: "POST",
        body: formSub,
        headers: headersList,
    });
    if (!response.ok) {
        return false;
    }
    const data = await response.json();
    return data;
};

export const deleteAllCookies = () => {
    setCookie("access_token", "", -1);
    setCookie("refresh_token", "", -1);
    setCookie("api-pa-token", "", -1);
};

const saveFile = (blob: Blob, filename: string) => {
    const a = document.createElement("a");
    document.body.appendChild(a);
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = filename;
    a.click();
    setTimeout(() => {
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
    }, 0);
};

export { useWindowSize, replaceMenuName, scrollTop, serverResponseOK, replaceAreaName, saveFile };
