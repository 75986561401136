import {
	Card,
	H4,
	Image
} from '@maggioli-design-system/react'

interface ICategoryCard {
	className: string,
	src: string,
	category: string,
}

const DEFAULT_IMAGE_URL: string = `${process.env.PUBLIC_URL}/assets/images/categoria-amministrazione.jpg`;

// Elemento singolo della griglia delle categorie
const CategoryCard = ({ className, src = DEFAULT_IMAGE_URL, category = 'Amministrazione' }: ICategoryCard): JSX.Element => {

	return (
		<Card gutter="none" className={`overflow-hidden p-0 text-center ${className}`}>
			<Image src={src} aspectRatio="3:2" />
			<H4 className="py-4 px-6">
				{category}
			</H4>
		</Card>
	)
}

export default CategoryCard;
