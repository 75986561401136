import axios from "axios";
import * as Constants from "../lib/Constants";
import Singleton from "../lib/Singleton";

export const getParam = (): RequestInit => {
    const myHeaders: Headers = new Headers();
    myHeaders.append("Authorization", `Bearer ${Singleton.getActiveToken()}`);
    myHeaders.append("Accept", `application/json`);
    myHeaders.append("Content-Type", `application/json`);

    const requestOptions: RequestInit = {
        method: "GET",
        headers: myHeaders,
    };

    return requestOptions;
};

// restituisce le info di un libro dal redazionale
export const getRedaBook = async (isbn: string): Promise<any> => {
    const config: Object = {
        method: "get",
        url: `${Constants.expressURL}/api/books/view/${isbn}`,
        headers: {
            "Authorization": `Bearer ${Singleton.getActiveToken()}`,
        },
    };

    return await axios(config);
};

// restituisce una lista di libri dal redazionale
export const getRedaBooks = async (
    page: number = 0,
    year: string = "Tutti",
    category: string = null,
    format = "Tutti",
    size: number = 10,
): Promise<any> => {
    const yearFilter: string = year !== "Tutti" ? `&anno=${year}` : "";
    const categoryFilter: string = category !== null ? `&aree=${category}` : "";
    const formatFilter: string = format !== "Tutti" ? (format === "iLibro" ? "&elaborazioneXligo=true" : "&elaborazioneXligo=false") : "";
    const pageFilter: string = size > 10 ? "&page=0" : `&page=${page}`;
    const config: Object = {
        method: "get",
        url: `${Constants.expressURL}/api/books/search?${pageFilter}${yearFilter}${categoryFilter}${formatFilter}&size=${size}&sort=anno,mese,desc`,
        headers: {
            "Authorization": `Bearer ${Singleton.getActiveToken()}`,
        },
    };

    return await axios(config);
};

// restituisce dal redazionale i libri che hanno titolo o isbn che fa match con la stringa cercata
export const searchRedaBooks = async (searchText: string, page: number = 0): Promise<any> => {
    const config: Object = {
        method: "get",
        url: `${Constants.expressURL}/api/books/search?query=${searchText}&page=${page}&prodotto=biblioteca`,
        headers: {
            "Authorization": `Bearer ${Singleton.getActiveToken()}`,
        },
    };

    return await axios(config);
};

// restituisce gli anni di pubblicazione dei libri dal redazionale
export const getRedaBookYears = async (): Promise<any> => {
    const config: Object = {
        method: "get",
        url: `${Constants.expressURL}/api/books/years`,
        headers: {
            "Authorization": `Bearer ${Singleton.getActiveToken()}`,
        },
    };

    return await axios(config);
};

export const getTree = async (id: number | string, callback: Function) => {
    try {
        const response = await fetch(`${Constants.expressURL}/api/documents/voce-albero/${id}`, getParam());
        if (response.ok) {
            const data = await response.json();
            callback(data);
        } else {
            callback(response.status);
        }
    } catch (e) {
        callback(e);
    }
};

export const getIndex = async (isbn: number | string, callback: Function) => {
    try {
        const response = await fetch(`${Constants.expressURL}/api/books/view/${isbn}/linkless-outline`, getParam());
        if (response.ok) {
            const data = await response.json();
            callback(data);
        } else {
            callback(response.status);
        }
    } catch (e) {
        callback(e);
    }
};

export const getChildrenTree = async (id: number, callback: Function) => {
    try {
        const response = await fetch(`${Constants.expressURL}/api/documents/voce-albero/${id}/children`, getParam());
        if (response.ok) {
            const data = await response.json();
            callback(data);
        } else {
            callback(response.status);
        }
    } catch (e) {
        callback(e);
    }
};

export const getRedaDocuments = async (id: number, page: number = 1, size: number = 20, callback: Function) => {
    try {
        const response = await fetch(
            `${Constants.expressURL}/api/documents/voce-albero/search?categorie=${id}&size=${size}&page=${page}`,
            getParam(),
        );
        if (response.ok) {
            const data = await response.json();
            callback(data.data, parseInt(data.total));
        } else {
            callback(response.status);
        }
    } catch (e) {
        callback(e);
    }
};

export const downloadDocument = async (id: number, callback: Function) => {
    try {
        const response = await fetch(`${Constants.expressURL}/api/documents/${id}/download`, getParam());

        if (response.ok) {
            const data = await response.blob();
            callback(data);
        } else {
            callback(response.status);
        }
    } catch (e) {
        callback(e);
    }
};

export const getProvvedimenti = async (id: number, page: number = 1, size: number = 20, callback: Function) => {
    try {
        const response = await fetch(`${Constants.expressURL}/api/document/provvedimento/${id}?size=${size}&page=${page}`, getParam());
        if (response.ok) {
            const data = await response.json();
            callback(data.data, parseInt(data.total));
        } else {
            callback(response.status);
        }
    } catch (e) {
        callback(e);
    }
};
