import React, { ChangeEvent, useState } from 'react'
import {
    Detail,
    Icon,
    Card,
    Grid,
    H5,
    Select,
    UList,
    UListItem,
} from '@maggioli-design-system/react'

interface IILibroCard {
    className: string,
}

const ILibroCard = ({ className }: IILibroCard): JSX.Element =>

    <Card className={className}>

        {/* Descrizione iLibro */}
        <div>
            <img alt="ilibro logo" className="h-12 mb-2" loading="lazy" src={`${process.env.PUBLIC_URL}/assets/images/iLibro.svg`}/>
            <Detail><b>iLibro</b> è la versione digitale del libro cartaceo arricchita con le seguenti funzionalità:</Detail>
            <UList iconSize="xsmall" autoPunctuation={false}>
                <UListItem><Detail>collegamento ipertestuale che consente l’immediata visualizzazione della normativa, prassi e giurisprudenza citata nel libro</Detail></UListItem>
                <UListItem><Detail>testi normativi sempre disponibili in versione aggiornata, con la possibilità di consultazione anche in modalità “multivigenza”</Detail></UListItem>
                <UListItem><Detail>motore di ricerca per parole chiave che consente l’immediata visualizzazione dei contenuti di interesse per il lettore</Detail></UListItem>
            </UList>
        </div>

        {/* Descrizione allegati */}
        <div>
            <img alt="approfondimenti logo" className="h-12 mb-2" loading="lazy" src={`${process.env.PUBLIC_URL}/assets/images/logo-contenuti-aggiuntivi.svg`}/>
            <Detail><b>Contenuti aggiuntivi</b></Detail>
            <Detail>Materiali online integrativi (schemi di atti, modulistica, flow-chart, video, ecc.)</Detail>
        </div>

        {/* Alert */}
        <div>
            <Icon name="warning"/>
            <Detail><b>Attenzione</b></Detail>
            <Detail>Nella versione iLibro è possibile che non sia attivo il collegamento ipertestuale per alcuni provvedimenti di normativa, prassi e giurisprudenza. La funzionalità può essere soggetta a revoca da parte dell’Editore.</Detail>
        </div>
    </Card>

interface IFilterYear {
    data: string[],
    selectYear: Function,
}

// Filtro per anno
const FilterYear = ({ data, selectYear }: IFilterYear): JSX.Element => {

    const [year, setYear] = useState<string>('Tutti');

    const setSelectYear = (value: string) => {
        setYear(value);
        selectYear(value);
    }
                                              
    if(data.length > 0) {
        return (
            <Grid gutter="xsmall">
                <H5>Anno di edizione</H5>
                <Select data={['Tutti', ...data]} value={year} 
                        onChange={(e: ChangeEvent<HTMLInputElement>) => setSelectYear(e.target.value)}/>
            </Grid>
        )
    } else {
        return <div></div>;
    }
    
}

export {ILibroCard, FilterYear}