import { ISubInfo } from "../views/SubscribeStep2";

export const subscriptionAreas: string[] = [
    "Bilancio, Contabilità e Tributi",
    "Amministrazione",
    "Personale e Organizzazione",
    "Polizia e Attività Economiche",
    "Ufficio Tecnico",
    "Servizi Demografici",
    "Appalti e Contratti",
];
export const subscriptionPeriods: string[] = ["1 anno", "3 anni"];

// Costanti Redazionale
export const expressURL: string =
    process.env.NODE_ENV === "development" ? "http://localhost:6565" : "https://bibliotecadigitale.maggioli.it/core";

// Costanti Approfondimenti
export const approURL: string = "https://approfondimenti.maggiolicloud.it/auth/api/v2";
export const approUploadsURL: string = "https://approfondimenti.maggiolicloud.it/uploads/";
export const approUser: string = process.env.REACT_APP_APPRO_USER;
export const approPassword: string = process.env.REACT_APP_APPRO_PASS;

export const mailTextContact: string =
    "<h1>Grazie per averci contattato!</h1>" +
    "Provvederemo a rispondere alla tua richiesta il prima possibile.<br/><br/>" +
    "In caso di necessità non esitare a contattare il nostro servizio clienti: <br/> Tel. 0541 628200 <br/>" +
    "(Da lunedì a venerdì dalle ore: 8.30 alle 17.30)";
export const mailTextSub: string =
    "<h1>Grazie per la tua richiesta!</h1>" +
    "Sarai contattato quanto prima da un nostro commerciale per una proposta di preventivo.<br/><br/>" +
    "In caso di necessità non esitare a contattare il nostro servizio clienti: <br/> Tel. 0541 628200 <br/>" +
    "(Da lunedì a venerdì dalle ore: 8.30 alle 17.30)";

export const thankYouTextSub: string = "Grazie per la tua richiesta!<br/>A breve sarei contattato per una proposta commerciale!";
export const thankYouTextContact: string = "Grazie per la tua richiesta, ti contatteremo a breve!";

export function mailText(mailType: string) {
    switch (mailType) {
        case "sales":
            return mailTextSub;
        case "contact":
            return mailTextContact;
        default:
            return "";
    }
}

export function tyText(mailType: string) {
    switch (mailType) {
        case "sales":
            return thankYouTextSub;
        case "contact":
            return thankYouTextContact;
        default:
            return "";
    }
}

// crea un cookie
export const setCookie = (name: string, value: string, seconds: number) => {
    const date = new Date();
    date.setTime(date.getTime() + seconds * 1000);
    const expires = "; expires=" + date.toUTCString();
    document.cookie = name + "=" + (value || "") + expires + "; Secure; SameSite=Lax; path=/";
};

// se c'è, recupera un cookie. altrimenti ritorna null
export const getCookie = (name: string) => {
    const nameEQ = name + "=";
    const ca = document.cookie.split(";");
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === " ") {
            c = c.substring(1, c.length);
        }
        if (c.indexOf(nameEQ) === 0) {
            return c.substring(nameEQ.length, c.length);
        }
    }
    return null;
};

export const clientID: string = "bibliotecadigitale";
export const clientSecret: string = "dS68nUkKpWUqoGpihVuCUdd7brJV8jxB";

// payload di keycloak
export const keycloakPayload = {
    "realm": "maggioli",
    "auth-server-url": "https://sso.maggioli.com/",
    "ssl-required": "external",
    "resource": clientID,
    "credentials": {
        "secret": clientSecret,
    },
    "confidential-port": 0,
};

export const privacyURL: string = "https://privacy.maggiolicloud.it";

// url di redirect in cui si torna una volta loggati su keycloak
export const keycloakRedirectUrl: string =
    process.env.NODE_ENV === "development" ? "http://localhost:3000/" : "https://bibliotecadigitale.maggioli.it";

export const oneDayInSeconds: number = 24 * 60 * 60;

export const footerMessageSubMail = (subInfo: ISubInfo | null): string => {
    if (subInfo === null) {
        return "";
    }
    const areasList: string =
        subInfo.areas[0] === "all"
            ? subscriptionAreas.toString().replace(/, /g, ",").replace(/,/g, ", ")
            : subInfo.areas.toString().replace(/, /g, ",").replace(/,/g, ", ");
    return `La richiesta fa riferimento all'abbonamento riguardante le seguenti informazioni: <br/>
                            Aree tematiche -> ${areasList} <br/>
                            Durata -> ${subInfo.period}.`;
};
