import React, { useEffect, useState } from "react";
import Book from "./Book";
import { Card, Grid, H3, H4, Download, Radio, InputText, Modal, ExternalLink, Paragraph, UList, UListItem } from "@maggioli-design-system/react";
import * as AttachService from "../services/Attachments";
import * as BookService from "../services/Books";
import { IBook } from "../Interfaces/IBook";
import { IArea } from "../Interfaces/IArea";
import { IAttach } from "../Interfaces/IAttach";
import { ILoader } from "../Interfaces/ILoader";
import { IProdotto } from "../Interfaces/IProdotto";
import { IRedaHandler } from "../Interfaces/IRedaHandler";
import { saveFile } from "../lib/Functions";
import { approPassword, approURL, approUser } from "../lib/Constants";

interface IAttachments {
    visible: boolean;
    hideModal: Function;
    book: IBook;
    loader: ILoader;
    redazionale: IRedaHandler;
    idProdotti?: IProdotto[];
}

interface CustomAttach {
    attach: IAttach[];
    filtered: IAttach[];
}

const Attachments = ({ visible, hideModal, book, loader, idProdotti, redazionale }: IAttachments): JSX.Element => {
    const [attach, setAttach] = useState<CustomAttach>({ attach: [], filtered: [] });
    const [simulatore, setSimulator] = useState<string>("");
    const [areas, setAreas] = useState<IArea[]>([]);
    const [searchText, setSearchText] = useState<string>("");
    const [firstLoad, setFirstLoad] = useState<boolean>(true);

    useEffect(() => {
        if (visible) {
            loader.setLoader(true);
            AttachService.getAttachments(book.isbn)
                .then((result) => {
                    loader.setLoader(false);
                    if (!result.data.status) {
                        return;
                    }
                    result.data.data !== null && setAttach({ attach: result.data.data, filtered: result.data.data });
                    result.data.areas !== null && setAreas(result.data.areas);
                    result.data.simulatore !== null && setSimulator(result.data.simulatore.meta_value);
                    result.data.id_bancadati !== null && redazionale.setIdBancadati(result.data.id_bancadati.meta_value);
                    redazionale.setIsbn(book.isbn);
                })
                .catch((e) => console.log(e));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visible]);

    useEffect(() => {
        if (redazionale.idBancadati) {
            BookService.getTree(redazionale.idBancadati, (result: any) => {
                if (result === 404) {
                    console.log("error");
                } else {
                    redazionale.setTree(result);
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [redazionale.idBancadati]);

    useEffect(() => {
        if (firstLoad) {
            setFirstLoad(false);
            return;
        }

        if (searchText.length > 0) {
            setAttach((p) => ({
                ...p,
                filtered: attach.attach.filter((elem) => elem.title.toLowerCase().includes(searchText.toLowerCase())),
            }));
        } else {
            setAttach((p) => ({ ...p, filtered: attach.attach }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchText]);

    const selectCategory = (value: string) => {
        if (value === "0") {
            setAttach((p) => ({ ...p, filtered: attach.attach }));
        } else {
            setAttach((p) => ({
                ...p,
                filtered: attach.attach.filter((elem) => elem.area_id === value),
            }));
        }
    };

    const closeModal = () => {
        redazionale.setIdBancadati(undefined);
        redazionale.setTree(undefined);
        hideModal();
    };

    const onClickDownload = async (attach: IAttach) => {
        if (attach.type === "video") {
            window.open(attach.guid, "_blank");
            return;
        }
        loader.setLoader(true);
        const res = await fetch(`${approURL}/get/attachments/download/${attach.id}`, {
            method: "GET",
            headers: {
                "Authorization": "Basic " + btoa(`${approUser}:${approPassword}`),
                "Content-Type": "application/json",
            },
        });
        loader.setLoader(false);
        if (!res.ok) {
            return;
        }
        const data = await res.blob();
        saveFile(data, `${attach.title}.${attach.type}`);
    };

    return (
        <Modal
            classNameWindow="max-w-6xl"
            maxHeight
            visible={visible}
            onCancel={closeModal}
            position="center"
            wide={true}
            header={false}
            footer={false}
            contentOnly
        >
            <Grid template="attachments" className="overflow-none">
                <div className="bg-adjust-tone p-8 relative">
                    <Grid className="sticky top-8" columns="1">
                        {/* Componente libro in alto a sinistra della modale degli allegati */}
                        {book !== null && (
                            <Book
                                data={book}
                                actions={false}
                                update={null}
                                setShow={null}
                                setIsbn={null}
                                iLibro={null}
                                hasAttach={true}
                                setShowModal={null}
                                allFavorites={false}
                                setAttachModal={null}
                                idProdotti={idProdotti}
                            />
                        )}

                        {/* Barra di ricerca allegati */}
                        <InputText
                            value={searchText}
                            icon="data-search"
                            placeholder="Cerca nei contenuti aggiuntivi..."
                            onChange={(e) => setSearchText(e.target.value)}
                        />

                        {/* Filtro allegati per categoria */}
                        {areas.length > 1 && (
                            <Grid gutter="xsmall">
                                <Radio onChange={(e) => selectCategory(e.target.value)} value="0" name="category" isChecked>
                                    Tutti
                                </Radio>
                                {areas.map((elem, i) => (
                                    <Radio key={i} name="category" onChange={(e) => selectCategory(e.target.value)} value={elem.id}>
                                        {elem.title}
                                    </Radio>
                                ))}
                            </Grid>
                        )}
                        {simulatore !== "" && (
                            <Paragraph>
                                {" "}
                                Quiz: <ExternalLink>{simulatore}</ExternalLink>
                            </Paragraph>
                        )}
                    </Grid>
                </div>

                <Grid className="bg-adjust-tone-20 p-8 overflow-scroll" rows="fit-vertically">
                    <H3>Contenuti aggiuntivi</H3>

                    {/* Tabella allegati */}
                    <Grid columns="1">
                        {/* Redazionale se c'è */}
                        {redazionale.tree !== undefined && (
                            <Grid className="gap-2">
                                <UList iconSize="normal" text="text-secondary text-secondary--detail" className="p-0">
                                    <div onClick={() => redazionale.setRedaShow(true)}>
                                        <UListItem
                                            icon="file-folder"
                                            className="bg-adjust-tone-20 hover:bg-brand-maggioli-19 hover:text-brand-maggioli-06 cursor-pointer transition-colors p-2 rounded"
                                        >
                                            {redazionale.tree.voce}
                                        </UListItem>
                                    </div>
                                </UList>
                            </Grid>
                        )}

                        {attach.filtered.map((elem, i) => {
                            const name = elem.type !== "video" ? `${elem.title}.${elem.guid.split(".").pop()}` : elem.title + ".mp4";

                            if (elem.type === "video" && elem.description.includes("iframe")) {
                                return (
                                    <Card key={i}>
                                        <Grid gutter="xsmall">
                                            <H4>{elem.title}</H4>
                                            <p dangerouslySetInnerHTML={{ __html: elem.description.replaceAll("fullscreen;", "") }} />
                                        </Grid>
                                    </Card>
                                );
                            }

                            const tempArea: IArea[] = areas.filter((a) => a.id.toString() === elem.area_id);
                            const categoryName: string = tempArea.length > 0 ? tempArea.pop().title : "";
                            return (
                                <div key={i}>
                                    <Download
                                        fileName={name}
                                        target="_blank"
                                        showExt={false}
                                        onClick={() => onClickDownload(elem)}
                                        className="shadow-lg hover:shadow-xl"
                                        category={categoryName}
                                    />
                                </div>
                            );
                        })}
                    </Grid>
                </Grid>
            </Grid>
        </Modal>
    );
};

export default Attachments;
