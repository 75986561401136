import { Image } from "@maggioli-design-system/react";
import React, { useEffect } from "react";
import { expressURL } from "../lib/Constants";
import { getParam } from "../services/Books";
import ContentLoader from "react-content-loader";

interface IBookCover {
    isbn: string;
    bookPage?: boolean;
}

const BookCover = ({ isbn, bookPage = false }: IBookCover): JSX.Element => {
    const [src, setSrc] = React.useState<string>("");
    const [loading, setLoading] = React.useState<boolean>(false);

    useEffect(() => {
        getFreeBookCover();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isbn]);

    const getFreeBookCover = async () => {
        setLoading(true)
        const response = await fetch(`${expressURL}/api/books/view-cover/${isbn}`, getParam());
        setLoading(false)
        if (response.ok) {
            const data = await response.blob();
            setSrc(URL.createObjectURL(data));
        }
    };

    if (loading) {
        return <ContentLoader speed={2} viewBox="0 0 40 55" backgroundColor="#f3f3f3" foregroundColor="#ecebeb">
            <rect x="0" y="0" rx="3" ry="3" width="40" height="55" />
        </ContentLoader>;
    }

    return <Image className={`rounded-md shadow-lg ` + (!bookPage ? "w-32 relative z-0 mr-4" : "") } src={src} />;
};

export default BookCover;
