import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { Grid, Row, Caption, LabelDetail, H5, Tag } from "@maggioli-design-system/react";
import { IBook } from "../Interfaces/IBook";
import * as Months from "../lib/Months";
import { IUserSubscription } from "../Interfaces/IUserSubscription";
import { IProdotto } from "../Interfaces/IProdotto";
import BookCover from "./BookCover";
import { getCookie } from "../lib/Constants";

interface IWhishlist {
    hasAttach: boolean;
    update: Function;
    allFavorites: boolean;
    user: IUserSubscription;
    data: IBook;
}

interface IWhishlistItem {
    id: IBook;
    hasAttach: boolean;
}

const Whishlist = ({ data, user, allFavorites, update, hasAttach }: IWhishlist): JSX.Element => {
    const isLogged: boolean = getCookie("refresh_token") !== null;
    const [added, setAdded] = useState<boolean>(false);

    useEffect(() => {
        const whishlist: IWhishlistItem[] = JSON.parse(localStorage.getItem("whishlist"));
        if (whishlist && whishlist.filter((elem: IWhishlistItem) => elem.id.isbn === data.isbn).length > 0) {
            setAdded(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const addToWhislist = (data: IBook) => {
        const whishlist: IWhishlistItem[] = JSON.parse(localStorage.getItem("whishlist"));
        if (!whishlist) {
            const object: IWhishlistItem[] = [
                {
                    id: data,
                    hasAttach: hasAttach,
                },
            ];
            localStorage.setItem("whishlist", JSON.stringify(object));
        } else {
            whishlist.push({ id: data, hasAttach: hasAttach });
            localStorage.setItem("whishlist", JSON.stringify(whishlist));
        }

        setAdded(true);
    };

    const removeToWhislist = (data: IBook) => {
        const whishlist: IWhishlistItem[] = JSON.parse(localStorage.getItem("whishlist"));
        const removed: IWhishlistItem[] = whishlist.filter((elem: IWhishlistItem) => elem.id.isbn !== data.isbn);
        localStorage.setItem("whishlist", JSON.stringify(removed));
        if (typeof update === "function") {
            update();
        }
        setAdded(false);
    };

    if (!isLogged) {
        return null;
    }

    // Tasto aggiungi/rimuovi dai preferiti
    return added || allFavorites ? (
        <Tag icon="data-favorite" className="cursor-pointer px-0 text-status-error-05" onClick={() => removeToWhislist(data)}>
            <Caption className="color-adjust-tone-08">Rimuovi dai preferiti</Caption>
        </Tag>
    ) : (
        <Tag icon="data-unfavorite" className="cursor-pointer px-0" onClick={() => addToWhislist(data)}>
            <Caption className="color-adjust-tone-08">Aggiungi ai preferiti</Caption>
        </Tag>
    );
};

interface CustomBook {
    update: Function;
    setShow: Function;
    setIsbn: Function;
    setShowModal: Function;
    setAttachModal: Function;
    user?: IUserSubscription;
    data: IBook;
    hasAttach: boolean;
    iLibro: boolean;
    allFavorites: boolean;
    actions: boolean;
    idProdotti?: IProdotto[];
}

// Label novità
export const BookLabel = ({ className, ...restProps }) => (
    <LabelDetail
        htmlTag="div"
        className={`bg-opacity-90 bg-label-green-08 text-label-green-18 shadow flex w-32 px-4 py-1 justify-center ${className || ""}`}
        {...restProps}
    >
        {restProps.children || "Novità"}
    </LabelDetail>
);

const Book = ({
    setShow,
    setIsbn,
    setShowModal,
    user,
    data,
    hasAttach,
    setAttachModal,
    iLibro,
    update,
    allFavorites,
    actions,
}: CustomBook): JSX.Element => {
    const open = (isbn: string) => {
        if (!data.hasAccessToExtraContent) {
            setShowModal(true);
        } else {
            setIsbn(isbn);
            setShow(true);
        }
    };

    const onClickAttachments = () => {
        if (!data.hasAccessToExtraContent) {
            setShowModal(true);
        } else {
            setAttachModal({ book: data, visible: true });
        }
    };

    const onClickLibro = () => {
        sessionStorage.setItem("scrollPage", window.scrollY.toString());
        sessionStorage.setItem("book", JSON.stringify(data));
        sessionStorage.setItem("book-hasAttach", hasAttach.toString());
    };

    const bookSlug: string = `${data.name.replaceAll(" ", "-").replaceAll("/", "-").replaceAll(",", "-")}-${data.isbn}`;
    const btnIcon: string = iLibro ? "logo-ilibro" : "";
    const isNew = data.mese >= new Date().getMonth() && data.anno === new Date().getFullYear();

    return (
        <Grid gutter="small" rows="fit-vertically">
            <Row gutter="small" className="items-start">
                {/* Immagine del libro */}
                <Link className="min-w-8 relative overflow-visible rounded-md" to={`/dettaglio/${bookSlug}`} onClick={onClickLibro}>
                    {isNew && <BookLabel className="absolute bottom-0 left-0 right-0 z-10" />}
                    <BookCover isbn={data.isbn} />
                </Link>

                {/* Info libro */}
                <Grid gutter="xsmall" className="py-3">
                    <Link key={data.isbn} to={`/dettaglio/${bookSlug}`} onClick={onClickLibro}>
                        <H5>{data.name.length > 60 ? `${data.name.slice(0, 60)}...` : data.name}</H5>
                    </Link>
                    <Grid gutter="xxsmall">
                        <Caption>
                            <strong>Autori:</strong>{" "}
                            {data.autori.join(", ").length > 30 ? `${data.autori.join(", ").slice(0, 30)}...` : data.autori.join(", ")}
                        </Caption>
                        <Caption>{`${Months.getName(data.mese)} ${data.anno}`}</Caption>
                    </Grid>
                    <Whishlist hasAttach={hasAttach} update={update} data={data} user={user} allFavorites={allFavorites} />
                </Grid>
            </Row>

            {/* Bottoni iLibro e allegati */}
            {actions && (
                <Row>
                    <Tag icon={btnIcon} chip className="cursor-pointer hover:background-color-brand-maggioli-18" onClick={() => open(data.isbn)}>
                        <Caption>Consulta il volume</Caption>
                    </Tag>
                    {hasAttach && (
                        <Tag
                            icon="additional-contents"
                            chip
                            className="cursor-pointer hover:background-color-brand-maggioli-18"
                            onClick={() => onClickAttachments()}
                        >
                            <Caption>Contenuti aggiuntivi</Caption>
                        </Tag>
                    )}
                </Row>
            )}
        </Grid>
    );
};

export default Book;
