import {
    H3,
    H4
} from '@maggioli-design-system/react'

export default function Conditions(): JSX.Element {

    return (
        <div className="px-8 background-color-adjust-tone-19">
            <div className="padding-normal view-limit text-conditions">
                <H3 className="text-center vertical-padding-xxsmall">Condizioni generali del contratto</H3>
                <div>
                    <H4>Art. 1 - Accettazione del contratto</H4>
                    La presente proposta di contratto è subordinata all’accettazione da parte di Maggioli S.p.A. (di seguito per brevità Maggioli). Il contratto si intenderà perfezionato con l’inizio della prestazione da parte di Maggioli. Resta inteso che in caso di mancata accettazione da parte di Maggioli, nulla sarà dovuto da parte del Committente.
                </div>
                <div className="mt-5">
                    <H4>Art. 2 - Oggetto del contratto</H4>
                    La presente proposta di contratto ha per oggetto la fornitura del servizio denominato “Biblioteca Digitale”, alle condizioni concordate all’atto dell’acquisto e alle ulteriori condizioni specificate nel prosieguo della presente.
                </div>
                <div className="mt-5">
                    <H4>Art. 3 - Durata</H4> 
                    La presente proposta di contratto è vincolante per il Committente dal momento della sua sottoscrizione e così per la durata concordata. Per quanto concerne la data di inizio dell’utilizzo
                    del servizio “Biblioteca Digitale” , per convenzione viene pattuito che essa decorrerà dalla data di trasmissione delle credenziali di accesso di attivazione da parte di Maggioli.
                </div>
                <div className="mt-5">
                    <H4>Art. 4 - Facoltà di recesso</H4> 
                    È riconosciuta al Committente la facoltà di recedere in qualsiasi momento dal contratto a condizione che il recesso venga comunicato a Maggioli a mezzo raccomandata A.R.
                    La risoluzione avverrà decorsi 30 (trenta) giorni dal ricevimento della raccomandata. Resta inteso che in caso di recesso anticipato il Committente s’impegna a corrispondere a Maggioli, a titolo di penale,
                    un importo del canone di abbonamento pari al numero di mensilità di utilizzo del servizio, fatturato e non fatturato, per i quali non siano ancora scaduti i termini di pagamento indipendentemente
                    dall’avvenuta fatturazione anticipata del canone di abbonamento. Se entro 30 giorni antecedenti alla scadenza dell’abbonamento, non verrà comunicato un diverso ammontare della quota di
                    abbonamento per l’anno successivo (o per il triennio successivo nel caso di abbonamento triennale), l’abbonamento si intende rinnovato alle stesse condizioni per uguale periodo successivo e così di periodo in periodo.
                </div>
                <div className="mt-5">
                    <H4>Art. 5 - Corrispettivo</H4>
                    Il Committente verserà l’intero importo del canone relativo alla prima annualità del servizio di abbonamento secondo i modi ed i tempi concordati all’atto dell’acquisto. Il pagamento dei canoni di abbonamento relativi alle successive due annualità dovranno essere effettuati entro 30 (trenta) giorni dalla data della relativa fattura. Le suddette fatture saranno inviate in
                    via anticipata ad inizio di ogni singola annualità. Qualora, alla scadenza del termine su indicato non sia avvenuto il pagamento dei canoni di abbonamento, si procederà automaticamente, senza che sia necessaria la costituzione in mora, all’applicazione ed al computo degli interessi moratori così come previsto, in attuazione della direttiva comunitaria 2000/35, recepita dal DLgs 231 approvata in
                    via definitiva dal Consiglio dei Ministri in data 20 settembre 2002. Il tasso di interesse applicabile verrà determinato nella misura prevista ex art. 5 comma 1 del su indicato DLgs. In caso di mancato
                    pagamento entro 30 giorni dalla scadenza dei termini indicati al I ed al II capoverso del presente articolo, Maggioli avrà facoltà di sospendere il servizio previo apposito preavviso di gg 10. In caso
                    di mancato pagamento entro 15 giorni dalla sospensione, Maggioli avrà la facoltà di risolvere il contratto ipso iure ai sensi dell’art. 1456 c.c, addebitando all’utente l’intero importo del canone di
                    abbonamento indicato nell’ordine, nonché l’importo degli interessi moratori. Maggioli si riserva inoltre, così come previsto ex art. 6 del Dlgs di cui sopra, di esercitare il diritto di ottenere il risarcimento
                    dei costi sostenuti per il recupero delle somme non tempestivamente corrispostegli nonché il risarcimento del maggior danno subìto, salvo il caso in cui il ritardo non sia imputabile direttamente al debitore.
                </div>
                <div className="mt-5">
                    <H4>Art. 6 - Inadempimento</H4>
                    La morosità dell’abbonato nei pagamenti da qualsiasi causa derivante, darà a Maggioli il diritto di sospendere la possibilità di accesso al servizio “Biblioteca Digitale” nonché
                    di esigere l’immediato pagamento dell’intero saldo residuo in un’unica soluzione, comprensivo di spese e interessi moratori ex DLgs 231/2002, ovvero di risolvere il contratto ai sensi dell’art. 1456
                    c.c., salvo l’eventuale maggior danno sofferto. Una volta sanata la morosità, Maggioli, su richiesta dell’abbonato, si riserva la facoltà di ripristinare la fornitura del servizio al prezzo concordato all’atto
                    dell’acquisto.
                </div>
                <div className="mt-5">
                    <H4>Art. 7 - Invio aggiornamenti</H4>
                    Maggioli metterà a disposizione del Committente i prodotti nella versione in vigore al momento dell’accettazione della presente proposta di contratto e, in seguito, gli aggiornamenti dei prodotti che Maggioli riterrà opportuno realizzare.
                </div>
                <div className="mt-5">
                    <H4>Art. 8 - Uso esclusivo del prodotto</H4>
                    I prodotti di proprietà di Maggioli sono tutelati dalle leggi sul copyright, dalle disposizioni dei trattati internazionali e da tutte le altre leggi nazionali applicabili.
                    Di conseguenza il Committente è tenuto a considerare i servizi internet e i prodotti telematici come ogni altro materiale coperto da copyright. Il Committente non può in ogni caso riprodurre i servizi
                    internet e i prodotti telematici, il manuale o comunque tutto il materiale d’accompagnamento ai servizi internet e ai prodotti telematici. Il Committente non può dare in locazione o in leasing i servizi
                    internet e i prodotti telematici in esso contenuti. Il Committente non potrà convertire, decodificare, decompilare o disassemblare i servizi internet e i prodotti telematici in esso presenti. Il Committente
                    viene inoltre edotto dal fatto che il contenuto editoriale dei servizi internet e i prodotti telematici può essere utilizzato in tutto o in parte, solo a scopo di consultazione personale e professionale e quindi
                    con l’esclusione di ogni e qualsiasi altro tipo di utilizzazione dei servizi internet e i prodotti telematici che implichi la riproduzione del materiale in esso contenuto per l’approntamento di altre opere da
                    pubblicare in qualsiasi forma. Sono ugualmente vietati la riproduzione e l’uso dei marchi e di ogni altro segno distintivo di cui è titolare Maggioli.
                </div> 
                <div className="mt-5">
                    <H4>Art. 9 - Limitazioni di responsabilità</H4>
                    Maggioli garantisce che il servizio “Biblioteca Digitale” funzionerà in sostanziale conformità con quanto indicato nel materiale informativo; pertanto Maggioli
                    non potrà essere ritenuta responsabile per eventuali danni causati al Committente e/o a terzi da un suo uso improprio o maldestro. Maggioli non garantisce che le funzioni contenute in “Biblioteca
                    Digitale” soddisfino le esigenze del cliente per il raggiungimento di un fine particolare, per cui non sarà responsabile per i danni causati all’utente e/o a terzi per mancato guadagno, interruzione
                    dell’attività, nonché per qualsiasi altra perdita economica, derivante dall’uso del servizio. In ogni caso ogni eventuale responsabilità di Maggioli, sarà limitata ad un importo corrispondente al prezzo
                    corrisposto per il servizio, con conseguente obbligo di restituzione di detto prezzo.
                </div> 
                <div className="mt-5">
                    <H4>Art. 10 - Modificazioni del contratto</H4> 
                    Ogni e qualsiasi eventuale sostituzione e/o modificazione e/o integrazione del presente contratto dovrà essere espressamente pattuita fra Maggioli e il Committente per iscritto a pena di inefficacia.
                </div> 
                <div className="mt-5">
                    <H4>Art. 11 - Sospensione abbonamento</H4> 
                    È data facoltà a Maggioli di eliminare il servizio dal proprio catalogo in qualsiasi momento. Qualora Maggioli decidesse di eliminare il servizio dal proprio
                    catalogo, il contratto si risolverà ipso iure con decorrenza immediata, con diritto del Committente al rimborso del prezzo eventualmente già corrisposto per le prestazioni non godute e fatto salvo naturalmente il diritto di Maggioli al pagamento del corrispettivo maturato sino al momento della risoluzione.
                </div> 
                <div className="mt-5">
                    <H4>Art. 12 - Modificazione dei dati anagrafici</H4> 
                    Qualsiasi variazione di domicilio/sede, di ragione sociale, di dati fiscali del Committente e dei dati relativi alla Banca o Istituto di Credito incaricato dal Committente ad effettuare i pagamenti nei confronti di Maggioli, dovrà essere comunicata a Maggioli a mezzo di lettera raccomandata A.R., al ricevimento della quale la variazione stessa diverrà operativa.
                </div> 
                <div className="mt-5">
                    <H4>Art. 13 - Foro competente</H4> 
                    Per ogni e qualsiasi controversia relativa all’interpretazione, all’esecuzione e alla cessazione del presente contratto, è competente in via esclusiva il Foro di Rimini, esclusa ogni eventuale competenza concorrente.
                </div>
                <div className="mt-5">
                    <H4>Art. 14 - Tracciabilità dei flussi finanziari</H4> 
                    L’appaltatore assume tutti gli obblighi di tracciabilità dei flussi finanziari di cui all’art. 3 della legge 13/08/2010, n. 136 e successive modifiche ed
                    integrazioni. Ogni pagamento dovrà essere eseguito esclusivamente al domicilio della Società fornitrice in Santarcangelo di Romagna (RN) a mezzo di bollettino di c/c postale allegato alla fattura o
                    tramite bonifico bancario (preferibilmente sul contro presso BANCO BPM S.p.A. - filiale di Cesena - IBAN: IT 59 O 05034 23900 000000112925) utilizzando i conti correnti dedicati anche in via non
                    esclusiva alle commesse pubbliche già comunicati a mezzo PEC.
                </div>
                <br/>
            </div>
        </div>
    )
}

